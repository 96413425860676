import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'


import { SharedModule, CoreComponentsModule } from '@app/shared'
import { PipesModule } from '@app/shared/pipes'
import { FormsModule } from '@angular/forms'
import { NgxDatatableModule } from '@swimlane/ngx-datatable'

import { ExpensesComponent } from './containers'
import { ExpenseFilterComponent } from './components/expense-filter/expenses-filter.component'
import { ExpenseModalComponent } from './components/expense-modal/expense-modal.component'
import { ExpenseListComponent } from './components/expense-list/expense-list.component'
import { ExpenseDetailsComponent } from './components/expense-details/expense-details.component'
import { ExpenseService } from './services'


const ExpenseRouting = RouterModule.forChild([
    {
        path: '',
        component: ExpensesComponent
    },
    {
        path: ':expenseId',
        component: ExpensesComponent
    }
])


@NgModule({
    declarations: [
        ExpensesComponent,
        ExpenseFilterComponent,
        ExpenseModalComponent,
        ExpenseListComponent,
        ExpenseDetailsComponent
    ],
    imports: [
        ExpenseRouting,
        CommonModule,
        SharedModule,
        CoreComponentsModule,
        PipesModule,
        FormsModule,
        NgxDatatableModule
    ],
    exports: [
        ExpenseModalComponent,
        ExpenseDetailsComponent
    ],
    providers: [ExpenseService]
})
export class ExpensesModule {
    constructor() {
        console.log('[Module] : Loaded ExpensesModule')
    }
}
