<div class="bg-white p-4 flex justify-between">
  <h4 class="text-xl text-gray-900">
    Expense {{expense?.expenseNumber}}
  </h4>
  <button class="btn btn-link" type="button" (click)="close()" aria-label="Close">
    <span aria-hidden="true">
      <i class="fas fa-times"></i>
    </span>
  </button>
</div>
<div class="bg-white px-4 pb-4">
  <app-locked-item
    *ngIf="expense"
    [type]="FOLLOW_UP_RESOURCE.EXPENSE"
    [itemName]="expense.expeneNumber"
    [lockedItem]="lockedItem$ | async"
    [askToUnlockTimer]="askToUnlockListener$ | async"
    (askToUnlock)="askToUnlock()"
    (updateLock)="updateLock()"
  ></app-locked-item>
  <div class="flex justify-between items-center">
    <div class="w-1/5">
      <app-filters [status]="[{ name: 'Details', key: 'Details' },
      { name: 'History', key: 'History' }]" [filters]="{selectedTab}" [property]="'selectedTab'" (filterSet)="selectedTab = $event.selectedTab"></app-filters>
    </div>
    
    <div class="text-base flex items-baseline">
      <span class="mr-2">Created by:</span>
      <app-account-info-popover [creator]="expense?.creator"></app-account-info-popover>
      <span class="ml-2">{{expense?.createdAt | date : 'M/d/yy | h:mm a'}}</span> 
    </div>
  </div>
  
  <div [ngSwitch]="selectedTab">
    <form class="flex">
      <div [style.display]="selectedTab===TABS.DETAILS?'block':'none'" class="col-md-8 pr-0">
        <div class=" m-0 p-0">
          <div class="col-12 text-center">
            <app-multi-file-viewer [files]="files" (onDelete)="updateExpense({ _id: this.expense._id, fileIds: $event })"></app-multi-file-viewer>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="TABS.DETAILS" class="col-md-4 mt-2">
        <app-expense-details
          [expense]="expense"
          [taskCount]="taskCount$ | async"
          [accountsCharts]="accountsCharts$ | async"
          [isReadOnly]="isReadOnly"
          [currentUser]="currentUser$ | async"
          [users]="users$ | async"
          [categories]="categories$ | async"
          (onSave)="save($event)"
          (onChange)='save($event)' 
          (onDelete)='onDelete($event)'
        >
  
        </app-expense-details>
      </div>  
    </form>
    <div>
      <ng-container *ngSwitchCase="TABS.HISTORY">
        <ng-container>
          <app-history-logs [resourceType]="'expenses'" [resourceId]="expense._id"></app-history-logs>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

